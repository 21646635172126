<template>
    <div class=''>
        <nf-breadcrumb :breadList='breadList'></nf-breadcrumb>
        <div class='baseDetail'>
            <div class='flex top'>
                <div class='swiperContent'>
                    <el-carousel class="top_img" v-if='detail && detail.urls.length>0'>
                        <el-carousel-item v-for="item in detail.urls" :key="item">
                            <img :src="item" alt="">
                        </el-carousel-item>
                    </el-carousel>

                    <img class="top_img" src='http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan/trademarket/2019-12-05/9d28408b-c75f-49e3-a8e9-0fc97dbf75a2.png'
                    alt v-else/>
                </div>
                <div>
                    <div class='baseName flex'>
                        <span class='flex_length'>{{detail.baseName}}</span>
                        <router-link :to='{path: "/downLoad"}' tag='a'>基地实况</router-link>
                    </div>
                    <div class='flex_length'>
                        <p class='title'>基地介绍</p>
                        <p class='baseDetail'>{{detail.baseDetail}}</p>
                    </div>
                </div>
            </div>
            <div class='detaile'>
                <div class='first'>所属单位基本情况介绍</div>
                <div class='second'>{{detail.companyInfo}}</div>
            </div>
            <div class='detaile'>
                <div class='first'>基地数据一览</div>
                <div class='flex'>
                    <div class="list" @click="list_click('育种概况')">
                        <p class="list_text">育种概况</p>
                    </div>
                    <div class="list" @click="list_click('成果一览')">
                        <p class="list_text">成果一览</p>
                    </div>
                    <div class="list" @click="list_click('人员分布')">
                        <p class="list_text">人员分布</p>
                    </div>
                    <div class="list" @click="list_click('人才队伍')">
                        <p class="list_text">人才队伍</p>
                    </div>
                    <div class="list" @click="list_click('产业发展')">
                        <p class="list_text">产业发展</p>
                    </div>
                    <div class="list" @click="list_click('共享实验室')">
                        <p class="list_text">共享实验室</p>
                    </div>
                    <div class="list" @click="list_click('其他展示')">
                        <p class="list_text">其他展示</p>
                    </div>
                    <div class="list" @click="list_click('基地导航')">
                        <p class="list_text">基地导航</p>
                    </div>
                </div>
                
            </div>
            <el-dialog
                title=""
                :visible.sync="tip"
                width="30%">
                <div class='msg'><span>【{{msg}}】</span>请至手机APP查看</div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import Swiper from "swiper";
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import Vue from 'vue';
import { Dialog } from 'element-ui';
Vue.use(Dialog);

export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
            bannerSwiper: null,
            detail: '',
            breadList: [{
                name: '资源共享'
            }, {
                name: '共享发布',
                path: '/dataShare'
            }, {
                name: '基地详情'
            }],
            tip: false,
            msg: ''
        };
    },
    mounted() {
        this.getListContent();
    },
    methods: {
        // startSwiper() {
        //     this.bannerSwiper.autoplay.stop();
        // },
        // stopSwiper() {
        //     this.bannerSwiper.autoplay.start();
        // },
        // 通过id获取基地详情数据
        async getListContent() {
            const id = this.$route.query.id;
            const api = this.$fetchApi.baseDetailsContent.api;
            const res = await this.$fetchData.fetchPost({}, api + `/${id}`, 'json');
            this.detail =  res.result;
            // this.$nextTick(() => {
            //     this.swiper();
            // });
        },
        list_click(msg) {
            this.tip = true;
            this.msg = msg;
        }
        // 轮播
        // swiper() {
        //     this.bannerSwiper = new Swiper(".swiper-container", {
        //         direction: "horizontal",
        //         loop: true, // 循环模式选项
        //         observer: true, //修改swiper自己或子元素时，自动初始化swiper
        //         observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //         autoplay: {
        //             delay: 3000,
        //             disableOnInteraction: false
        //         },
        //         pagination: {
        //         el: ".swiper-pagination",
        //         dynamicBullets: false,
        //         dynamicMainBullets: 3,
        //         bulletElement: "li",
        //         clickable: true
        //         }
        //     });
        // }
    }
};
</script>
<style lang="less" scoped>
    .baseDetail{
        width:1200px;
        margin:30px auto;
    }
    .swiperContent{
        width:500px;
        margin-right:25px;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .swiper-container{
        width: 100%;
	    height: 280px;
        img{
            width: 500px;
	        height: 100%;
        }
    }
    .top{
        align-items: flex-start;
        .baseName{
            font-size: 28px;
            color: #333333;
            margin:20px 0 28px;
            a{
                width: 112px;
                height: 36px;
                background-color: #265ae1;
                border-radius: 2px;
                color: #ffffff;
                font-size:16px;
                text-align: center;
                line-height: 36px;
            }
        }
        p.title{
            color: #333333;
            font-size: 16px;
            font-weight: bold;
             width: 150px;
             text-align: center;
        }
        p.title::before{
            content: '';
            display: block;
            width: 150px;
            height: 2px;
            background-color: #448afc;
            margin-bottom:14px;
        }
        .baseDetail{
            text-indent: 2em;
            font-size: 16px;
            color: #333333;
            line-height: 36px;
            width:675px;
        }
    }
    .detaile{
        font-size:16px;
        color: #333;
        width:1180px;
        margin:40px auto 0;
        .first{
            font-size:18px;
            padding-bottom:18px;
        }
        .second{
            text-indent: 2em;
            line-height: 36px;
            font-size: 16px;
        }
        .first::before{
            border-left: 6px solid #265ae1;
            height:20px;
            content: '';
            margin-right:10px;
        }
        .list{
            width: 128px;
            height: 46px;
            background-color: #ffffff;
            border-radius: 22px 22px 24px 22px;
            border: solid 1px #5598fa;
            text-align: center;
            line-height: 46px;
            margin-right:15px;
            margin-top:26px;
            margin-bottom:50px;
            cursor: pointer;
        }
        .list:hover{
            background: #5598fa;
            color:#fff;
        }
    }
    .msg{
        text-align: center;
        font-size:18px;
        span{
            color:#5598fa;
        }
        padding-bottom:20px;
    }
</style>